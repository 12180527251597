import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import 'devextreme/dist/css/dx.carmine.compact.css';

import svMessages from 'devextreme/localization/messages/sv.json';
import { locale, loadMessages } from "devextreme/localization";
import './custom.css'

export default class App extends Component {
   static displayName = App.name;
   constructor(props) {
      super(props);
      loadMessages(svMessages);
      locale(navigator.language);
   }
   render() {
      return (
         <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/counter' component={Counter} />
            <AuthorizeRoute path='/fetch-data' component={FetchData} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
         </Layout>
      );
   }
}
