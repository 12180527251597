import React, { Component } from 'react';
import 'devextreme/data/odata/store';
import {
   Column,
   DataGrid,
   Format,
   MasterDetail,
   Scrolling,
   Paging,
   HeaderFilter,
   SearchPanel,
   // FilterRow,
   // HeaderFilter,
   // GroupPanel,
   // Editing,
   // Grouping,
   // Lookup,
   // Summary,
   // RangeRule,
   // RequiredRule,
   // StringLengthRule,
   // GroupItem,
   // TotalItem,
   // ValueFormat
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import authService from './api-authorization/AuthorizeService'

export class Home extends Component {
   static displayName = Home.name;
   constructor(props) {
      super(props);
      this.state = {
         table: 'ZooliganTEST',
         date: new Date(),
         disabledDates: [],
         first: new Date(2000, 0, 0)
      };
      this.now = new Date();
      this.max = new Date();

      this.tableValueChanged = this.tableValueChanged.bind(this);
      this.dateValueChanged = this.dateValueChanged.bind(this);
   }
   get test() {
      return this.state.table;
   }
   get currentTable() {
      if (this.state !== undefined) {
         return this.state.table;
      }
      else {
         return 'ZooliganTEST';
      }
   }
   get currentDate() {
      if (this.state !== undefined) {
         return this.state.date;
      }
      else {
         return new Date();
      }
   }
   get currentDiasbledDates() {
      if (this.state !== undefined) {
         return this.state.disabledDates;
      }
      else {
         return [];
      }
   }
   get currentFirstDate() {
      if (this.state !== undefined) {
         return this.state.first;
      }
      else {
         return new Date(2021, 7, 1);
      }
   }

   tableValueChanged(e) {
      this.setState({
         table: e.value
      });
      this.setDisabledDates();
   }

   dateValueChanged(e) {
      this.setState({
         date: e.value
      });
   }
   async populateTableSelect() {
      //const token = await authService.getAccessToken();
      const response = await fetch('/api/tables', {
         //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      this.setState({ forecasts: data, loading: false });
   }
   setDisabledDates() {
      if (this.state === undefined) {
         return [];
      }
      fetch(`/api/log/datedata?table=${this.currentTable}`)
         .then(resp => resp.json())
         .then(data => {
            this.setState({
               first: new Date(data.first)
            });
            this.setState({
               disabledDates: data.disabled.map(x => new Date(x))
            });

         })
         .catch(function () {

         });
   }

   render() {
      return (
         <div className="form-group row">
            <div className="col-lg-3 col-md-4 col-sm-6">
               <label htmlFor="select_table">Kund:</label>
               <SelectBox
                  id="select_table"
                  dataSource={createStore({
                     key: 'index_kund',
                     loadUrl: '/api/tables',

                     onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.xhrFields = { withCredentials: true };
                     }
                  })}
                  displayExpr="name"
                  valueExpr="name"
                  onValueChanged={this.tableValueChanged} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
               <label htmlFor="select_date">Datum:</label>
               <DateBox
                  id="select_date"
                  disabledDates={this.currentDiasbledDates}
                  defaultValue={this.now}
                  type="date"
                  max={this.max}
                  min={this.currentFirstDate}
                  onValueChanged={this.dateValueChanged}
               />
            </div>
            <DataGrid
               id="productGrid"
               onRowClick={this.rowClick}
               dataSource={createStore(
                  {
                     key: 'id',
                     loadUrl: `/api/log?table=${this.currentTable}&date=${this.currentDate.toLocaleDateString()}`,
                     onBeforeSend: (method, ajaxOptions) => {
                        // ajaxOptions.xhrFields = { withCredentials: true };
                     }
                  })
               }
               showBorders={true}
               selection={{ mode: 'single' }}
               height={'calc(100vh - 150px)'}
               rowAlternationEnabled={true}
               remoteOperations={true}>
               <HeaderFilter visible={true} />
               {/*               <SearchPanel visible={true} width={240} placeholder="Sök..." />*/}
               <Scrolling mode="virtual" rowRenderingMode="virtual" />
               <Paging defaultPageSize={100} />
               <MasterDetail enabled={true} render={renderDetail} />
               <Column dataField="timeStamp" dataType="date" caption="Tid" width={100}>
                  <Format type="longTime" />
                  <HeaderFilter allowSearch={true} />
               </Column>
               <Column dataField="level" caption="Nivå" width={100}>
                  <HeaderFilter allowSearch={true} />
               </Column>
               <Column dataField="sourceContext" caption="Klass" width="auto">
                  <HeaderFilter allowSearch={true} />
               </Column>
               <Column dataField="memberName" caption="Metod" width="auto">
                  <HeaderFilter allowSearch={true} />
               </Column>
               
               <Column dataField="machineName" caption="Dator" width="auto">
                  <HeaderFilter allowSearch={true} />
               </Column>
               <Column dataField="version" caption="Version" width="auto">
                  <HeaderFilter allowSearch={true} />
               </Column>
            </DataGrid>
         </div>
      );
   }
   rowClick(e) {
      if (!e.isExpanded) {
         e.component.collapseAll(-1);
         e.component.expandRow(e.key);
      }
      else {
         e.component.collapseRow(e.key);
      }
   }
   selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
   }
}
function renderDetail(props) {
   let { memberName, sourceContext, message, filePathNr, exception, environmentUserName, machineName, version} = props.data;
   return (
      <table>
         <tbody>
            <tr><td className="log_info">Metod:</td><td className="log_message">{sourceContext} &gt; {memberName}</td></tr>
            <tr><td className="log_info">Parametrar:</td><td className="log_message">{message}</td></tr>
            <tr><td className="log_info">Användare:</td><td className="log_message">{environmentUserName} [{machineName}]</td></tr>
            <tr><td className="log_info">Version:</td><td className="log_message">{version}</td></tr>
            <tr><td className="log_info">Källa:</td><td className="log_message">{filePathNr}</td></tr>
            <tr><td className="log_info last_row">Felmeddelande:</td><td className="log_message last_row">{exception}</td></tr>
         </tbody>
      </table>
   );
}


